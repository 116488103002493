import React from "react";
import image1 from "../assets/PCP1.jpg";
import image2 from "../assets/PCP2.jpg";

const PCPPage = () => {
  return (
    <div className="bg-gray-50 p-8 min-h-screen mt-14">
      <div className="max-w-6xl mx-auto space-y-16">
        {/* Header Section */}
        <header className="text-center">
          <h1 className="text-5xl font-bold text-blue-700 leading-tight">
            Pneumatically Configurable Finishing
          </h1>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            A deterministic polishing technique employing pneumatic pressure for precise control of finishing forces, 
            enabling nano-level surface roughness and enhanced material removal rate.
          </p>
        </header>

        {/* Main Content Section */}
        <div className="space-y-16">
          {/* Section 1 */}
          <ContentSection
            title="Advanced Nanofinishing Process"
            description={[
              "Achieves surface roughness of a few nanometers using pneumatic pressure.",
              "Provides precise control of finishing forces across the polishing spot.",
              "Ideal for advanced applications requiring deterministic polishing.",
            ]}
            image={image1}
          />

          {/* Section 2 */}
          <ContentSection
            title="Process Modeling and Validation"
            description={[
              "Mathematical model developed to understand process physics.",
              "Incorporates parameters like feed rate, tool speed, and working gap.",
              "Experiments and simulations validated with minimal error (2.7%–15.9%).",
            ]}
            image={image2}
            reverse
          />
        </div>
      </div>
    </div>
  );
};

const ContentSection = ({
  title,
  description,
  image,
  reverse,
}: {
  title: string;
  description: string[];
  image: string;
  reverse?: boolean;
}) => {
  return (
    <section
      className={`flex flex-col ${
        reverse ? "md:flex-row-reverse" : "md:flex-row"
      } items-center gap-12`}
    >
      {/* Image */}
      <div className="flex-1">
        <img
          src={image}
          alt={title}
          className="rounded-xl shadow-lg w-full max-h-80 object-cover"
        />
      </div>

      {/* Text */}
      <div className="flex-1 space-y-4">
        <h2 className="text-3xl font-bold text-blue-600">{title}</h2>
        <ul className="list-disc pl-5 space-y-3 text-gray-700 text-lg">
          {description.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default PCPPage;
