import React from "react";

const RDConsultingProjects = () => {
  const rdConsultingProjects = [
    {
      projectNo: "MI03039N",
      title: "To Conduct 3D Graphy Engineering & Medical (3D GEM) 2024 event at IIT Delhi",
      sponsorAgency: "3D GRAPHY LLP, India",
      funds: 5250000,
      startDate: "6/20/2024",
      endDate: "3/19/2025",
    },
    {
      projectNo: "RP04764G",
      title: "Automated Bending of Orthopaedic Plates",
      sponsorAgency: "I-Hub Foundation for Cobotics (IHFC), India",
      funds: 3532118,
      startDate: "4/15/2024",
      endDate: "4/14/2026",
    },
    {
      projectNo: "CW16189N",
      title: "Conducting the 3D Graphy Engineering & Medical (3D GEM) 2024",
      sponsorAgency: "Trinity Media & Marketing Solutions, Mumbai, India",
      funds: 645000,
      startDate: "4/12/2024",
      endDate: "1/11/2025",
    },
    {
      projectNo: "RP04452G",
      title: "Development of multi-functional surgical instrumentation including robotic actuators, end-effectors and articulated arms for minimally invasive neurosurgery",
      sponsorAgency: "Indian Council of Medical Research, New Delhi, India",
      funds: 4851316,
      startDate: "2/1/2023",
      endDate: "1/31/2026",
    },
    {
      projectNo: "MI02731G",
      title: "Infinity Hyperloop (Sub Project No. 08 under CAIC Main Project No. MI00995)",
      sponsorAgency: "IRD, IIT Delhi, India",
      funds: 600000,
      startDate: "1/1/2023",
      endDate: "7/31/2024",
    },
    {
      projectNo: "RP04352G",
      title: "Deep learning-based lane detection with GNSS augmentation",
      sponsorAgency: "NMICPS TECHNOLOGY INNOVATION HUB ON AUTONOMOUS NAVIGATION (TiHAN) FOUNDATION, IIT HYDERABAD, India",
      funds: 2013572,
      startDate: "9/5/2022",
      endDate: "12/31/2024",
    },
    {
      projectNo: "RP04316N",
      title: "CSR Funding to enhance knowledge and learning to develop Digital twin for Smart Manufacturing",
      sponsorAgency: "Sonepar India Pvt. Ltd., India",
      funds: 1000000,
      startDate: "4/1/2022",
      endDate: "12/31/2025",
    },
    {
      projectNo: "RP04074N",
      title: "Research in the Area of Smart Manufacturing under CSR Grant",
      sponsorAgency: "Smart Controls India Ltd., India",
      funds: 1075000,
      startDate: "3/22/2021",
      endDate: "12/31/2025",
    },
    {
      projectNo: "RP04057N",
      title: "Development of Autonomous Driving Technologies",
      sponsorAgency: "Mahindra Susten Pvt. Ltd., India",
      funds: 1750000,
      startDate: "2/16/2021",
      endDate: "12/31/2024",
    },
    {
      projectNo: "RP03968F",
      title: "Micron UV Robot Design Challenge (This fund is given for CSR activity related to COVID-19)",
      sponsorAgency: "Micron Technology Foundation Inc.",
      funds: 1406784,
      startDate: "8/4/2020",
      endDate: "12/31/2024",
    },
    {
      projectNo: "MI01965G",
      title: "IRD Gap Period Assistantship to Sh. Hardik Arvindbhai Patel",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 420000,
      startDate: "5/11/2020",
      endDate: "5/10/2021",
    },
    {
      projectNo: "MI01954G",
      title: "IRD Gap Period Assistantship to Sh. Jitin Malhotra",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 520800,
      startDate: "5/11/2020",
      endDate: "12/31/2020",
    },
    {
      projectNo: "MI02212G",
      title: "Request for IRD Gap Period Assistantship for Mr. Onkar Chawla under the mentorship of Prof. Sunil Jha",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 520800,
      startDate: "5/11/2020",
      endDate: "5/10/2021",
    },
    {
      projectNo: "MI01956G",
      title: "IRD Gap Period Assistantship to Sh. Asish Kumar Sahu",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 420000,
      startDate: "5/11/2020",
      endDate: "12/31/2020",
    },
    {
      projectNo: "RP03689N",
      title: "5G Connected Teleoperated Car",
      sponsorAgency: "Reliance Industries Ltd.",
      funds: 1180000,
      startDate: "3/12/2019",
      endDate: "12/31/2024",
    },
    {
      projectNo: "MI01720G",
      title: "Auto Pharm (IRD Student Start-up Action Year 2018)",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 195000,
      startDate: "1/30/2018",
      endDate: "1/29/2019",
    },
    {
      projectNo: "MI01663G",
      title: "Brain Storming Meeting on Bio-Medical Device & Technology Development, Advanced Manufacturing Technologies and Technology Programme for Electric Mobility (TPEM) during 04.12.2017 at IIT Delhi",
      sponsorAgency: "Department of Science & Technology",
      funds: 162750,
      startDate: "12/4/2017",
      endDate: "3/3/2018",
    },
    {
      projectNo: "MI01574G",
      title: "To Conduct 2nd Meeting of Programme Advisory Committee on Device Development Programme of DST at Department of Mechanical Engineering on 3-4 August 2017",
      sponsorAgency: "Department of Science & Technology",
      funds: 1200000,
      startDate: "7/25/2017",
      endDate: "11/30/2017",
    },
    {
      projectNo: "RP03387G",
      title: "Design and Development of Magneto & Electro Rheological Systems",
      sponsorAgency: "Defence Materials & Stores Research & Development",
      funds: 7700000,
      startDate: "7/1/2017",
      endDate: "12/31/2019",
    },
    {
      projectNo: "MI01494G",
      title: "India-ROK Workshop on Robotics during 22nd to 23rd March 2017 at IIT Delhi",
      sponsorAgency: "Department of Science & Technology (International Bilateral Cooperation Division)",
      funds: 780000,
      startDate: "3/7/2017",
      endDate: "9/6/2017",
    },

    {
      projectNo: "MI01237",
      title: "IRD Gap Period Assistantship to Mr. Faiz Iqbal under the supervision of Dr. Sunil Jha, Department of Mechanical Engineering",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 336000,
      startDate: "10/1/2015",
      endDate: "9/30/2016",
    },
    {
      projectNo: "RP03027G",
      title: "Laser Based Marksmanship and Tactical Training Simulator (LMTTS)",
      sponsorAgency: "Simulator Development Division (Indian Army)",
      funds: 4900000,
      startDate: "2/24/2015",
      endDate: "12/30/2020",
    },
    {
      projectNo: "MI01107G",
      title: "IIT Delhi Open House",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 13900000,
      startDate: "3/1/2014",
      endDate: "12/31/2023",
    },
    {
      projectNo: "RP02658G",
      title: "Design & Development of CNC Magnetorheological Finishing (MRF) System",
      sponsorAgency: "Department of Science & Technology",
      funds: 12239200,
      startDate: "9/6/2012",
      endDate: "3/31/2016",
    },
    {
      projectNo: "MI00834",
      title: "Design of Efficient Algorithms to Synchronize GPS and Inertial Navigation System (TDP-IAS 2010 (II))",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 44500,
      startDate: "3/1/2011",
      endDate: "8/31/2011",
    },
    {
      projectNo: "RP02210G",
      title: "Magnetorheological Evaluation of Smart Polishing Fluid Properties and their Role in Nanofinishing",
      sponsorAgency: "Department of Science & Technology",
      funds: 1260000,
      startDate: "3/16/2009",
      endDate: "3/15/2012",
    },
    {
      projectNo: "MI00495",
      title: "Autonomous Vehicle Navigation Using Sensors Image Processing and Embedded Controls",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 100000,
      startDate: "8/24/2007",
      endDate: "8/31/2009",
    },
    {
      projectNo: "MI03040N",
      title: "Conduct 2nd Meeting of Programme Advisory Committee on Device Development Programme of DST at Department of Mechanical Engineering",
      sponsorAgency: "Department of Science & Technology (DST), India",
      funds: 1200000.00,
      startDate: "25-07-2017",
      endDate: "30-11-2017",
    },
    {
      projectNo: "MI03041N",
      title: "Design and Development of Magneto & Electro Rheological Systems",
      sponsorAgency: "Defence Materials & Stores Research & Development, India",
      funds: 7700000.00,
      startDate: "01-07-2017",
      endDate: "31-12-2019",
    },
    {
      projectNo: "MI03042N",
      title: "Design and Development of Automated Flexible Abrasive Flow Finishing (AF-AFF) Process for Finishing of Micro to Macro Complex Features",
      sponsorAgency: "Department of Science & Technology (DST), India",
      funds: 4307380.00,
      startDate: "09-09-2016",
      endDate: "08-09-2019",
    },
    {
      projectNo: "MI03043N",
      title: "Advanced CNC Micromachining System with Integrated Micro Tool Manufacturing",
      sponsorAgency: "Department of Science & Technology (DST), India",
      funds: 31743619.00,
      startDate: "11-05-2016",
      endDate: "10-05-2019",
    },
    {
      projectNo: "MI03044N",
      title: "Laser Based Marksmanship and Tactical Training Simulator (LMTTS)",
      sponsorAgency: "Simulator Development Division (Indian Army), India",
      funds: 4900000.00,
      startDate: "24-02-2015",
      endDate: "23-02-2018",
    },
    {
      projectNo: "MI03045N",
      title: "Design & Development of CNC Magnetorheological Finishing (MRF) System",
      sponsorAgency: "Department of Science & Technology (DST), India",
      funds: 12239200.00,
      startDate: "06-09-2012",
      endDate: "31-03-2016",
    },
    {
      projectNo: "MI03046N",
      title: "Magnetorheological Evaluation of Smart Polishing Fluid Properties and their Role in Nanofinishing",
      sponsorAgency: "Department of Science & Technology (DST), India",
      funds: 1260000.00,
      startDate: "16-03-2009",
      endDate: "15-03-2012",
    },
  ];

  const rdConsultingCP = [
    {
      projectNo: "MI02875G",
      title: "Power System for High-Speed network in Vacuum/Low pressure (under IRD Discover & Learn (1-2-3-4) India",
      sponsorAgency: "IRD, IIT Delhi",
      funds: 200000,
      startDate: "1/8/2024",
      endDate: "1/7/2025",
    },
    {
      projectNo: "RP04572G",
      title: "Design and development of an autonomous vehicle and investigating its stability and handling characteristics.",
      sponsorAgency: "NMICPS TECHNOLOGY INNOVATION HUB ON AUTONOMOUS NAVIGATION (TiHAN) FOUNDATION, IIT HYDERABAD, India",
      funds: 2500000,
      startDate: "7/10/2023",
      endDate: "1/9/2025",
    },
    {
      projectNo: "RP04437GC_SN",
      title: "Creation of Centre of Excellence (CoE) in Advanced Ultra-Precision Optics Manufacturing Technologies",
      sponsorAgency: "Department of Science and Technology (DST), India",
      funds: 118654200,
      startDate: "2/1/2023",
      endDate: "1/31/2028",
    },
    {
      projectNo: "RP04355G",
      title: "Design and development of diffractive optical element as spectral beam combiner for high power laser application",
      sponsorAgency: "DEPARTMENT OF DEFENCE RESEARCH & DEVELOPMENT ORGANISATION, MINISTRY OF DEFENCE, India",
      funds: 216636000,
      startDate: "11/15/2022",
      endDate: "11/14/2026",
    },
    {
      projectNo: "MI02182G",
      title: "Multiple Degrees of Freedom Robotic Manipulator for Minimally Invasive Neurosurgery. (MFIRP -IRD Funded AIIMS)",
      sponsorAgency: "IRD, IIT Delhi, India",
      funds: 1000000,
      startDate: "2/10/2020",
      endDate: "8/9/2022",
    },
    {
      projectNo: "MI01883G",
      title: "To Host Robocon",
      sponsorAgency: "DOORDARSHAN, DELHI, India",
      funds: 28380611,
      startDate: "2/6/2019",
      endDate: "3/28/2025",
    },
    {
      projectNo: "MI01799G",
      title: "Smart Infrastructure for an Electric Vehicle Ecosystem (Under IRD Grand Challenge Project Scheme)",
      sponsorAgency: "Ministry of Human Resource & Development, India",
      funds: 72348800,
      startDate: "8/16/2018",
      endDate: "8/15/2024",
    },
    {
      projectNo: "RP03546G",
      title: "Research in Human-Centered Robotics with special emphasis on Field and Bio-Medical Rehabilitation (Indo-Korea Joint Network Centre on Robotics)",
      sponsorAgency: "Department of Science & Technology, India",
      funds: 22377000,
      startDate: "4/27/2018",
      endDate: "3/31/2022",
    },
    {
      projectNo: "RP03346G",
      title: "Energy Efficient Buildings: Technology with Intelligence",
      sponsorAgency: "Department of Science & Technology, India",
      funds: 31841600,
      startDate: "4/3/2017",
      endDate: "10/2/2020",
    },
    {
      projectNo: "RP02994G",
      title: "Modelling of Advanced Materials for Simulation of Transformative Manufacturing Processes",
      sponsorAgency: "Department of Science & Technology (International Bilateral Cooperation Division), United Kingdom",
      funds: 10043600,
      startDate: "12/16/2014",
      endDate: "3/31/2018",
    },
    {
      projectNo: "RP02403G",
      title: "Development of Cryogenically Coupled Vibratory EDMProcesses to Machine Hard Materials and conducting Ceramics",
      sponsorAgency: "Department of Science & Technology, India",
      funds: 3575000,
      startDate: "10/7/2010",
      endDate: "3/31/2014",
    },
    {
      projectNo: "RP02350",
      title: "Adaptive Force Control of an Industrial Robot (KUKA KR-6) Equipped with Force/Torque Sensor (Sub project # 4 under the main project # RP02346)",
      sponsorAgency: "Board of Research in Nuclear Sciences (DAE), India",
      funds: 14300800,
      startDate: "5/4/2010",
      endDate: "7/31/2016",
    },
    {
      projectNo: "RP02302G",
      title: "Experimental Investigations and Analysis of Ultrasonic Assisted Magnetic Abrasive Finishing (UAMAF) Process",
      sponsorAgency: "Council of Scientific & Industrial Research (CSIR), India",
      funds: 2735476,
      startDate: "11/15/2009",
      endDate: "11/30/2013",
    },
    {
      projectNo: "MI00609",
      title: "Delivering in Two-Way Video Conference Made Twelve Master Level Courses in Manufacturing Engg.",
      sponsorAgency: "Addis Ababa University, Ethiopia",
      funds: 4994000,
      startDate: "3/18/2009",
      endDate: "9/30/2013",
    },
    {
      projectNo: "CW11523",
      title: "Visit and Interaction between Mechanical Engineering Students and Faculty at Addis Ababa University, Ethiopia",
      sponsorAgency: "Addis Ababa University, Ethiopia",
      funds: 4292322,
      startDate: "12/26/2008",
      endDate: "4/25/2011",
    },
    {
      projectNo: "RP03425G",
      title: "Energy Efficient Buildings: Technology with Intelligence",
      sponsorAgency: "Department of Science & Technology (DST), India",
      funds: 31841600,
      startDate: "03/04/2017",
      endDate: "02/04/2020",
    },
    {
      projectNo: "RP02994G",
      title: "Modelling of Advanced Materials for Simulation of Transformative Manufacturing Processes",
      sponsorAgency: "International Division, Dept. of Science & Techn., United Kingdom",
      funds: 10043600,
      startDate: "12/16/2014",
      endDate: "03/31/2018",
    },
    {
      projectNo: "RP02403G",
      title: "Development of Cryogenically Coupled Vibratory EDM Processes to Machine Hard Materials and Conducting Ceramics",
      sponsorAgency: "Department of Science & Technology (DST), India",
      funds: 3575000,
      startDate: "10/07/2010",
      endDate: "03/31/2014",
    },
    {
      projectNo: "RP02350G",
      title: "Adaptive Force Control of an Industrial Robot (KUKA KR-6) Equipped with Force/Torque Sensor (Sub-project #4 under the main project #RP02346)",
      sponsorAgency: "Board of Research in Nuclear Sciences (DAE), India",
      funds: 14300800,
      startDate: "04/05/2010",
      endDate: "07/31/2016",
    },
    {
      projectNo: "RP02302G",
      title: "Experimental Investigations and Analysis of Ultrasonic Assisted Magnetic Abrasive Finishing (UAMAF) Process",
      sponsorAgency: "Council of Scientific & Industrial Research (CSIR), India",
      funds: 2735476,
      startDate: "11/15/2009",
      endDate: "11/30/2013",
    },
  ];
  const sortedPI = rdConsultingProjects.sort((a, b) =>
    new Date(b.startDate.split("/").reverse().join("/")) -
    new Date(a.startDate.split("/").reverse().join("/"))
  );
  
  const sortedCP = rdConsultingCP.sort((a, b) =>
    new Date(b.startDate.split("/").reverse().join("/")) -
    new Date(a.startDate.split("/").reverse().join("/"))
  );
  
  return (
    <div className="container mx-auto px-2 py-8 min-h-screen flex flex-col items-center justify-start mt-12">
      {/* Centered Heading */}
      <h1 className="text-2xl md:text-3xl font-bold mb-8 text-center">
        R&D Consulting Projects(PI)
      </h1>

      {/* Table Container for RDConsultingProjects */}
      <div className="w-full overflow-x-auto mb-12">
        <table className="w-full table-auto border border-gray-600">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm text-white font-medium uppercase tracking-wider border border-gray-600">
                Sr. No.
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Project No.
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Title
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Sponsoring Agency
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-right text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Funds (Rs.)
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Start Date
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                End Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {rdConsultingProjects.map((project, index) => (
              <tr key={index}>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm font-medium text-gray-900 bg-gray-100 border border-gray-600 text-center">
                  {index + 1}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-left">
                  {project.projectNo}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-normal text-xs md:text-sm text-gray-700 bg-gray-100 border border-gray-600 text-left">
                  {project.title}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-normal text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-left">
                  {project.sponsorAgency}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-100 border border-gray-600 text-right">
                  {project.funds.toLocaleString()}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-center">
                  {project.startDate}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-100 border border-gray-600 text-center">
                  {project.endDate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Table Container for RDConsultingCP */}
      <div className="w-full overflow-x-auto mb-12">
      <h1 className="text-2xl md:text-3xl font-bold mb-8 text-center">
        R&D Consulting Projects(CP)
      </h1>
        <table className="w-full table-auto border border-gray-600">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm text-white font-medium uppercase tracking-wider border border-gray-600">
                Sr. No.
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Project No.
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Title
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Sponsoring Agency
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-right text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Sanctioned Funds (Rs.)
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Project Start Date
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Scheduled Completion Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {rdConsultingCP.map((project, index) => (
              <tr key={index}>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm font-medium text-gray-900 bg-gray-100 border border-gray-600 text-center">
                  {index + 1}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-left">
                  {project.projectNo}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-normal text-xs md:text-sm text-gray-700 bg-gray-100 border border-gray-600 text-left">
                  {project.title}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-normal text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-left">
                  {project.sponsorAgency}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-100 border border-gray-600 text-right">
                  {project.funds.toLocaleString()}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-center">
                  {project.startDate}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-100 border border-gray-600 text-center">
                  {project.endDate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RDConsultingProjects;
