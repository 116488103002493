import "./App.css";
import Homepage from "./pages/Homepage";
import { Route, Routes } from "react-router-dom";
import LocomotiveScroll from 'locomotive-scroll';
import Gallery from './pages/Gallery';
import InternationalJournal from './pages/InternationalJournal';
import ConferencePaper from './pages/ConferencePaper';
import FIITProjects from './pages/FIITProjects';
import Patents from './pages/Patents';
import BooksSupervised from './pages/BooksSupervised';
import Awards from './pages/Awards';
import Supervision from './pages/Supervision';
import RDConsultants from './pages/RDConsultants';
import Teaching from './pages/Teaching';

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Contact from "./pages/Contact";
import OGProjects from "./pages/OGProjects";
import AutonomousVehiclePage from "./pages/AutoVE";
import CNCMicromachiningPage from "./pages/CNCMicromachiningPage";
import BallEndMRFPage from "./pages/Ball";
import PCPPage from "./pages/PCPPage";
function App() {
  const locomotiveScroll = new LocomotiveScroll();

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/international-journal" element={<InternationalJournal />} />
        <Route path="/conference-paper" element={<ConferencePaper />} />
        <Route path="/fiit-projects" element={<FIITProjects />} />
        <Route path="/patents" element={<Patents />} />
        <Route path="/books-supervised" element={<BooksSupervised />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/supervision" element={<Supervision />} />
        <Route path="/rd-consultants" element={<RDConsultants />} />
        <Route path="/teaching" element={<Teaching />} />
        <Route path="/projects" element={<OGProjects />} />
        <Route path="/auto-ve" element={<AutonomousVehiclePage />} />
        <Route path="/cnc" element={<CNCMicromachiningPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ball" element={<BallEndMRFPage />} />
        <Route path="/pcp" element={<PCPPage />} />
      </Routes>
      {/* Include Chatbot here so it appears above the Footer */}
      <Footer />
    </div>
  );
}

export default App;
