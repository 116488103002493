import React from "react";
import cnc1 from "../assets/cnc1.jpg";
import cnc2 from "../assets/cnc2.jpg";
import cnc3 from "../assets/cnc3.jpg";
import cnc4 from "../assets/cnc4.jpg";
import cnc5 from "../assets/cnc5.jpg";


const CNCMicromachiningPage = () => {
  return (
    <div className="bg-gray-50 p-8 min-h-screen mt-14">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <header className="mb-12 text-center">
          <h1 className="text-5xl font-bold text-blue-700 leading-tight mb-4">
            Advanced CNC Micromachining for Precision Manufacturing
          </h1>
        </header>

        {/* Projects Section */}
        <div className="space-y-16">
          {/* Project 1 */}
          <ProjectSection
            title="Multi-Purpose Precision Micromachining Tool Head"
            image={cnc1}
            alt="Micromachining Tool Head"
            description={[
              "Capability for µ-milling, µ-EDM, µ-LBM, µ-grinding, and µ-drilling.",
              "Ensures versatility for different manufacturing needs with high precision.",
              "Optimized for a wide range of materials and applications.",
            ]}
            reverse={false}
          />

          {/* Project 2 */}
          <ProjectSection
            title="In-Situ Metrology for Tool Wear Monitoring"
            image={cnc2}
            alt="In-Situ Metrology"
            description={[
              "Uses optical microscope and 6-axis dynamometer for tool wear monitoring.",
              "Ensures real-time condition analysis during machining.",
              "Improves efficiency by reducing downtime and optimizing tool life.",
            ]}
            reverse={true}
          />

          {/* Project 3 */}
          <ProjectSection
            title="High-Speed Aerostatic Spindle"
            image={cnc3}
            alt="Aerostatic Spindle"
            description={[
              "Spindle speed up to 80,000 RPM with a 600 nm runout for high precision machining.",
              "Perfect for µ-milling, µ-grinding, and other fine precision tasks.",
              "Optimized to minimize vibration and maintain dimensional accuracy.",
            ]}
            reverse={false}
          />

          {/* Project 4 */}
          <ProjectSection
            title="Laser Micro Machining System"
            image={cnc4}
            alt="Laser Micro Machining"
            description={[
              "Uses pulsed fiber lasers for µ-LBM with a minimum spot size of 7 µm.",
              "Ideal for micro-component fabrication with high accuracy.",
              "Supports hybrid milling and grinding processes for intricate geometries.",
            ]}
            reverse={true}
          />

          {/* Project 5 */}
          <ProjectSection
            title="Motion Control and Automation"
            image={cnc5}
            alt="Motion Control"
            description={[
              "Five-axis automated motion control for precision machining.",
              "X-Y travel: 300 mm; Z-travel: 150 mm; with rotary axis accuracy within 30 arc-seconds.",
              "Incorporates touch probe and laser tool setting for referencing.",
            ]}
            reverse={false}
          />
        </div>
      </div>
    </div>
  );
};

const ProjectSection = ({
  title,
  image,
  alt,
  description,
  reverse,
}: {
  title: string;
  image: string;
  alt: string;
  description: string[];
  reverse: boolean;
}) => {
  return (
    <section className={`flex flex-col ${reverse ? "md:flex-row-reverse" : "md:flex-row"} items-center gap-8`}>
      {/* Image Section */}
      <div className="flex-1">
        <img
          src={image}
          alt={alt}
          className="rounded-lg shadow-lg w-full object-fit max-h-72"
        />
      </div>
      {/* Text Section */}
      <div className="flex-1">
        <h2 className="text-3xl font-bold text-blue-600 mb-4">{title}</h2>
        <ul className="list-disc pl-5 space-y-3 text-gray-700">
          {description.map((point, index) => (
            <li key={index} className="text-lg">{point}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default CNCMicromachiningPage;


