import React from 'react';

const Teaching = () => {
  const positions = [
    { period: 'Aug 2018 – Present', position: 'Professor', institute: 'I.I.T. Delhi' },
    { period: 'Dec 2012 – Aug 2018', position: 'Associate Professor', institute: 'I.I.T. Delhi' },
    { period: 'July 2007 - Dec 2012', position: 'Assistant Professor', institute: 'I.I.T. Delhi' },
  ];

  const courses = [
    'MEL120: Manufacturing Practices',
    'MCP101: Product Realization by Manufacturing',
    'MEP201: Mechanical Engineering Drawing',
    'MEP202: Design Innovation & Manufacturing',
    'MEL334: Low Cost Automation',
    'MEL411: Mechatronics',
    'MCL431: CAM & Automation',
    'MEL432: Microprocessor Applications in Manufacturing',
    'MEL749: Mechatronic Product Design',
    'MEL783: Automation in Manufacturing',
    'MCL784: Computer Aided Manufacturing',
  ];

  const developedCourses = ['MCL431: CAM & Automation'];

  const researchContributions = [
    'New process for finishing 3D surface using smart Magnetorheological polishing fluid is conceptualized and Experimental machine with automated process controller is developed.',
    'Development of Rotational Parallel Plate Magneto rheometer',
    'Development of Multi-process Advanced Micromachining System',
    'Automated Pneumatically configurable polishing Machine',
    'Development and demonstration of Smart Manufacturing technologies',
    'Automated Assembly Stations for Flexible manufacturing',
    'Multi-process Robotic Cell for Assembly & Inspection',
    'Cyber Physical Assembly Line',
    'CPS based Robotic Welding Cell',
    'Advanced Micromachining System',
    'Autonomous Mobile Robot Platform',
    '5-Axis CNC Ball End Magnetorheological Finishing Machine',
    'UV Robot for Automated Sanitization',
    'Smart Warehouse IoT Device',
    'Neuro Surgical instruments development',
    'Automatic Orthopedic reconstruction plate bending machine',
  ];

  const academicResponsibilities = [
    'Head, Central Workshop (Sep 2024 – Till date)',
    'Core Member of Center of Excellence for Bio-inspired Robotics and Drones (CoE-BIRD)',
    'Co-PI, DST CoE on Optics Manufacturing',
    'IHFC Grand Project Coordinator for Industry X.0',
  ];

  return (
    <div className="container mx-auto px-4 py-8 mt-10">
      <h1 className="text-3xl text-center font-bold mb-6">Teaching Experience</h1>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2 text-center">Positions Held</h2>
        <table className="min-w-full table-auto border border-gray-600">
          <thead className="bg-blue-600">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider border border-gray-300">Institute</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider border border-gray-300">Period</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider border border-gray-300">Position</th>
            </tr>
          </thead>
          <tbody>
            {positions.map((pos, index) => (
              <tr key={index}>
                <td className="px-4 py-2 text-left text-sm font-medium text-gray-900 border border-gray-300">{pos.institute}</td>
                <td className="px-4 py-2 text-left text-sm text-gray-700 border border-gray-300">{pos.period}</td>
                <td className="px-4 py-2 text-left text-sm text-gray-700 border border-gray-300">{pos.position}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Courses Taught</h2>
        <ul className="list-disc pl-5 space-y-2">
          {courses.map((course, index) => (
            <li key={index}>{course}</li>
          ))}
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Developed New Courses</h2>
        <ul className="list-disc pl-5 space-y-2">
          {developedCourses.map((course, index) => (
            <li key={index}>{course}</li>
          ))}
        </ul>
      </div>

      <h1 className="text-3xl text-center font-bold mb-4 mt-16">Industrial Experience</h1>
      <div className="w-full overflow-x-auto mb-8">
        <table className="min-w-full table-auto border border-gray-600">
          <thead className="bg-blue-600">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider border border-gray-300">Company</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider border border-gray-300">Period</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider border border-gray-300">Position</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300">
                IITD-AIA Foundation for Smart manufacturing
              </td>
              <td className="px-4 py-2 text-left text-sm text-gray-700 bg-gray-50 border border-gray-300">
                Nov 2017 – Till date
              </td>
              <td className="px-4 py-2 text-left text-sm text-gray-700 bg-gray-50 border border-gray-300">
                Director
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300">
                I.I.T. Kanpur
              </td>
              <td className="px-4 py-2 text-left text-sm text-gray-700 bg-gray-50 border border-gray-300">
                Dec 2006 – July 2007
              </td>
              <td className="px-4 py-2 text-left text-sm text-gray-700 bg-gray-50 border border-gray-300">
                Sr. Project Engineer (BARC Sponsored Project)
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300">
                TATA Motors
              </td>
              <td className="px-4 py-2 text-left text-sm text-gray-700 bg-gray-50 border border-gray-300">
                May 1998 – July 2000
              </td>
              <td className="px-4 py-2 text-left text-sm text-gray-700 bg-gray-50 border border-gray-300">
                Sr. Engineer (CAD/CAM)
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Significant Research Contributions at I.I.T Delhi</h2>
        <ul className="list-disc pl-5 space-y-2">
          {researchContributions.map((contribution, index) => (
            <li key={index}>{contribution}</li>
          ))}
        </ul>
      </div>

      <div>
        <h2 className="text-xl font-semibold mb-2">Academic & Administrative Responsibilities at I.I.T Delhi</h2>
        <ul className="list-disc pl-5 space-y-2">
          {academicResponsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Teaching;
