import React from 'react';

const Projects = () => {
  const projects = {
    ongoing: [
      {
        title: "Common Engineering Facility Center on Smart Manufacturing",
        sponsoringAgency: "Ministry of Heavy Industries, GoI",
        budget: "₹9,45,30,000",
        status: "Ongoing as Center of Excellence on Smart Manufacturing known as IITD-AIA Foundation for Smart Manufacturing as section-8 company founded with industry partners."
      },
      {
        title: "Development of multi-functional surgical instrumentation including robotic actuators, end-effectors and articulated arms for minimally invasive neurosurgery",
        sponsoringAgency: "Indian Council of Medical Research",
        budget: "₹99,76,200",
        status: "Ongoing, till 31-01-2026"
      },
      {
        title: "Deep learning-based lane detection with GNSS augmentation",
        sponsoringAgency: "TIH on Autonomous Navigation, (TiHAN), IIT Hyderabad",
        budget: "₹20,13,572",
        status: "Ongoing, till 31-12-2024"
      },
      {
        title: "Development of Autonomous Driving Technologies",
        sponsoringAgency: "Mahindra Susten Pvt. Ltd., India",
        budget: "₹17,50,000",
        status: "Ongoing, till 31-12-2026"
      },
      {
        title: "Micron UV Robot Design Challenge",
        sponsoringAgency: "Micron Technology Foundation Inc., Austria",
        budget: "₹14,06,784",
        status: "Ongoing, till 31-12-2024"
      },
      {
        title: "CSR Funding to enhance knowledge and learning to develop Digital twin for Smart Manufacturing",
        sponsoringAgency: "Sonepar India Pvt. Ltd., India",
        budget: "₹20,00,000",
        status: "Ongoing till 31-12-2025"
      },
      {
        title: "Research in the Area of Smart Manufacturing under CSR Grant",
        sponsoringAgency: "Smart Controls India Ltd., India",
        budget: "₹10,75,000",
        status: "Ongoing till 31-12-2025"
      },
      {
        title: "5G Connected Teleoperated Car",
        sponsoringAgency: "Reliance Industries Ltd., India",
        budget: "₹11,80,000",
        status: "Ongoing, till 31-12-2024"
      },
      {
        title: "Automated Bending of Orthopaedic Plates",
        sponsoringAgency: "Innovation Hub for Cobotics (IHFC), IIT Delhi",
        budget: "₹35,32,118",
        status: "Ongoing, till 14-04-2026"
      },
      {
        title: "Design and Development of a Pneumatically Configurable Finishing (PCF) System",
        sponsoringAgency: "Department of Science & Technology, India",
        budget: "₹48,65,480",
        status: "Ongoing, till 31-12-2025"
      }
    ],
    completed: [
      {
        title: "Design and Development of Automated Flexible Abrasive Flow Finishing (AF-AFF) Process for Finishing of Micro to Macro Complex Features",
        sponsoringAgency: "Department of Science & Technology, India",
        budget: "₹43,07,380",
        status: "Successfully Completed in 2020"
      },
      {
        title: "Design and Development of Magneto & Electro Rheological Systems",
        sponsoringAgency: "Defence Materials & Stores Research & Development, India",
        budget: "₹77,00,000",
        status: "Successfully Completed in 2019"
      },
      {
        title: "Advanced CNC Micromachining System with Integrated Micro Tool Manufacturing",
        sponsoringAgency: "Department of Science & Technology, India",
        budget: "₹3,17,43,619",
        status: "Successfully Completed in 2021. Developed a fully automated multi-process Micromachining System"
      },
      {
        title: "Secure and Resilient control of Robotic Systems in Cyber Physical Assembly Line",
        sponsoringAgency: "Innovation Hub for Cobotics (IHFC), IIT Delhi",
        budget: "₹42,00,000",
        status: "Successfully Completed in 2024"
      }
    ]
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 my-14">
      <div className="container mx-auto px-6 py-8 flex-grow">
        <h1 className="text-3xl font-bold mb-8 text-center text-gray-900">Ongoing / Completed Projects</h1>

        {/* Ongoing Projects Table */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Ongoing Projects</h2>
          <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
            <table className="min-w-full table-auto">
              <thead className="bg-gradient-to-r from-gray-200 to-gray-300 text-gray-700">
                <tr>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Project Title</th>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Sponsoring Agency</th>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Budget</th>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Status</th>
                </tr>
              </thead>
              <tbody>
                {projects.ongoing.map((project, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-300 hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : ''}`}
                  >
                    <td className="px-4 py-2 text-sm text-gray-700">{project.title}</td>
                    <td className="px-4 py-2 text-sm text-gray-700">{project.sponsoringAgency}</td>
                    <td className="px-4 py-2 text-sm text-gray-700">{project.budget}</td>
                    <td className="px-4 py-2 text-sm text-gray-700">{project.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Completed Projects Table */}
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Completed Projects</h2>
          <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
            <table className="min-w-full table-auto">
              <thead className="bg-gradient-to-r from-gray-200 to-gray-300 text-gray-700">
                <tr>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Project Title</th>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Sponsoring Agency</th>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Budget</th>
                  <th className="w-1/4 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-white bg-blue-600">Status</th>
                </tr>
              </thead>
              <tbody>
                {projects.completed.map((project, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-300 hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : ''}`}
                  >
                    <td className="px-4 py-2 text-sm text-gray-700">{project.title}</td>
                    <td className="px-4 py-2 text-sm text-gray-700">{project.sponsoringAgency}</td>
                    <td className="px-4 py-2 text-sm text-gray-700">{project.budget}</td>
                    <td className="px-4 py-2 text-sm text-gray-700">{project.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

