import React from "react";
import image1 from "../assets/Ball1.jpg";
import image2 from "../assets/Ball2.jpg";
import image3 from "../assets/Ball3.jpg";
import image4 from "../assets/Ball4.jpg";

const BallEndMRFPage = () => {
  return (
    <div className="bg-gray-50 p-8 min-h-screen mt-14">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <header className="mb-12 text-center">
          <h1 className="text-5xl font-bold text-blue-700 leading-tight mb-4">
            Ball End Magnetorheological Finishing Innovations
          </h1>
        </header>

        {/* Projects Section */}
        <div className="space-y-16">
          {/* Project 1 */}
          <ProjectSection
            title="New BEMRF Tool Design"
            description={[
              "Enhanced tool design for superior precision and efficiency.",
              "Optimized to handle a variety of materials with nano-level finishing.",
            ]}
            image={image1}
            reverse={false}
          />

          {/* Project 2 */}
          <ProjectSection
            title="Improved MR Fluid Delivery System"
            description={[
              "Ensures consistent and efficient fluid delivery to the finishing zone.",
              "Reduces wastage and enhances process reliability.",
            ]}
            image={image2}
            reverse={true}
          />

          {/* Project 3 */}
          <ProjectSection
            title="5-axis CNC MRF Controller"
            description={[
              "Provides high-precision control for complex geometries.",
              "Supports automated finishing processes with real-time adjustments.",
            ]}
            image={image3}
            reverse={false}
          />

          {/* Project 4 */}
          <ProjectSection
            title="Indigenous MR-polishing Fluid"
            description={[
              "Developed in-house to achieve superior finishing results.",
              "Tailored for a wide range of materials and applications.",
            ]}
            image={image4}
            reverse={true}
          />

          {/* Materials Section */}
          <MaterialSection
            title="Nano-finish Materials"
            materials={[
              "Hi Cr Steel",
              "Stainless Steel",
              "Copper",
              "Aluminium",
              "Polycarbonate",
              "Glass",
              "Silicon",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

const ProjectSection = ({
  title,
  description,
  image,
  reverse,
}: {
  title: string;
  description: string[];
  image: string;
  reverse: boolean;
}) => {
  return (
    <section
      className={`flex flex-col ${
        reverse ? "md:flex-row-reverse" : "md:flex-row"
      } items-center gap-8`}
    >
      {/* Image Section */}
      <div className="flex-1">
        <img
          src={image}
          alt={title}
          className="rounded-lg shadow-lg w-full object-fit max-h-72"
        />
      </div>
      {/* Text Section */}
      <div className="flex-1">
        <h2 className="text-3xl font-bold text-blue-600 mb-4">{title}</h2>
        <ul className="list-disc pl-5 space-y-3 text-gray-700">
          {description.map((point, index) => (
            <li key={index} className="text-lg">
              {point}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

const MaterialSection = ({
  title,
  materials,
}: {
  title: string;
  materials: string[];
}) => {
  return (
    <section className="bg-white shadow-xl rounded-lg p-8">
      <h2 className="text-3xl font-bold text-blue-600 mb-6 text-center">
        {title}
      </h2>
      <ul className="grid grid-cols-2 sm:grid-cols-3 gap-4 text-gray-800 text-lg text-center">
        {materials.map((material, index) => (
          <li
            key={index}
            className="bg-gray-100 py-2 px-4 rounded-md shadow-sm"
          >
            {material}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default BallEndMRFPage;
