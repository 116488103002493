import React from "react";
import auto1 from "../assets/Auto1.png";
import auto2 from "../assets/Auto2.jpg";
import auto3 from "../assets/Auto3.png";
import auto4 from "../assets/Auto4.png";
import auto5 from "../assets/Auto5.png";

const AutonomousVehiclePage = () => {
  return (
    <div className="bg-gray-50 p-8 min-h-screen mt-14">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <header className="mb-12 text-center">
          <h1 className="text-5xl font-bold text-blue-700 leading-tight mb-4">
            Development and Demonstration of Autonomous Electric Vehicle
          </h1>
        </header>

        {/* Projects Section */}
        <div className="space-y-16">
          {/* Project 1 */}
          <ProjectSection
            title="Simulated Environment for Autonomous Driving Using ROS"
            image={auto1}
            alt="Simulated Environment Diagram"
            description={[
              "Development of simulated environment to test autonomous driving on Mahindra e2o electric car.",
              "Simulated car model is based on the same communication interface as the physical vehicle.",
              "Input commands provided by an ACCU (Autonomous Car Control Unit) were sent to SGU (Secured Gateway Unit) using CAN commands, which are then sent to the car modules.",
              "Tested different techniques such as lane driving and SLAM using simulated sensor plugins.",
            ]}
            reverse={false}
          />

          {/* Project 2 */}
          <ProjectSection
            title="Lane Driving Using Computer Vision"
            image={auto2}
            alt="Lane Driving"
            description={[
              "Objective: To move a driverless EV on the road at Level 2 stage of autonomous driving with the help of lane following.",
              "Goals include analyzing robust computer vision approaches and implementing lane detection models.",
              "Testing performed on a live video feed of Mahindra e2o and in simulated environments.",
              "Motion planning algorithms designed for smooth steering and lane following.",
            ]}
            reverse={true}
          />

          {/* Project 3 */}
          <ProjectSection
            title="SLAM using Lidar"
            image={auto3}
            alt="SLAM using Lidar"
            description={[
              "Generation of a dense 3D map for IIT Delhi Campus using LIDAR data to be used for localization using particle filter approach.",
              "Accurate Localization of the vehicle in the IIT Delhi Campus map.",
              "Development of a Qt-based desktop application for real-time analysis of the information from the various sensors on the car.",
              "Segmentation of 2D LIDAR data to detect roads and lanes.",
            ]}
            reverse={false}
          />

          {/* Project 4 */}
          <ProjectSection
            title="Lane Detection using Machine Learning Implemented on Intel Neural Compute Stick-2"
            image={auto4}
            alt="Lane Detection using ML"
            description={[
              "Conventionally, computer vision algorithms were used to detect lanes, which involved processes like canny edge detection, hough transform, etc.",
              "Several convolutional models were trained and tested on a mix of datasets procured from the internet.",
            ]}
            reverse={true}
          />

          {/* Project 5 */}
          <ProjectSection
            title="Traffic Light Detection using Machine Learning Implemented on Intel Neural Compute Stick-2"
            image={auto5}
            alt="Traffic Light Detection"
            description={[
              "A deep neural network-based model was used for reliable detection and recognition of traffic lights using transfer learning.",
              "The model was trained on a dataset containing different images of traffic signals, both real and simulated, classified into four types.",
            ]}
            reverse={false}
          />
        </div>
      </div>
    </div>
  );
};

const ProjectSection = ({
  title,
  image,
  alt,
  description,
  reverse,
}: {
  title: string;
  image: string;
  alt: string;
  description: string[];
  reverse: boolean;
}) => {
  return (
    <section className={`flex flex-col ${reverse ? "md:flex-row-reverse" : "md:flex-row"} items-center gap-8`}>
      {/* Image Section */}
      <div className="flex-1">
        <img
          src={image}
          alt={alt}
          className="rounded-lg shadow-lg w-full object-cover max-h-72"
        />
      </div>
      {/* Text Section */}
      <div className="flex-1">
        <h2 className="text-3xl font-bold text-blue-600 mb-4">{title}</h2>
        <ul className="list-disc pl-5 space-y-3 text-gray-700">
          {description.map((point, index) => (
            <li key={index} className="text-lg">{point}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default AutonomousVehiclePage;
