import React from 'react';

const Awards = () => {
  const awards = [
    {
      year: "2016",
      agency: "Manufacturing Today",
      award: "National Technical Institutes Competition"
    },
    {
      year: "2017",
      agency: "GYTI",
      award: "Gandhian Young Technological Innovation Award"
    },
    {
      year: "2016",
      agency: "Aditya Birla Group",
      award: "National Technical Institutes Competition"
    }
  ];

  return (
    <div className="container mx-auto px-2 py-8 min-h-screen flex flex-col items-center justify-start mt-12">
      {/* Centered Heading */}
      <h1 className="text-2xl md:text-3xl font-bold mb-8 text-center">
        Awards and Recognitions
      </h1>

      {/* Table Container */}
      <div className="w-full overflow-x-auto">
        <table className="w-full table-auto border border-gray-600">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm text-white font-medium uppercase tracking-wider border border-gray-600">
                Sr. No.
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-center text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Year
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Agency
              </th>
              <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                Award/Honor Name
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {awards.map((award, index) => (
              <tr key={index}>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm font-medium text-gray-900 bg-gray-100 border border-gray-600 text-center">
                  {index + 1}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-center">
                  {award.year}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-nowrap text-xs md:text-sm text-gray-700 bg-gray-50 border border-gray-600 text-left">
                  {award.agency}
                </td>
                <td className="px-2 md:px-4 py-2 whitespace-normal text-xs md:text-sm text-gray-700 bg-gray-100 border border-gray-600 text-left">
                  {award.award}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Other Activities Section */}
      <h1 className="text-3xl font-bold mb-6 text-center mt-14">Other Activities</h1>

      <div className="flex-grow">
        <ul className="list-disc pl-6 space-y-4">
          <li className="relative pl-6 text-lg">
            <span className="absolute left-[-1.5rem] top-0 text-green-500 text-2xl">•</span>
            Leading Institute team of faculty and students on Driverless Car development for the Mahindra Driverless Car Challenge.
          </li>
          <li className="relative pl-6 text-lg">
            <span className="absolute left-[-1.5rem] top-0 text-green-500 text-2xl">•</span>
            Leading project on “Smart Manufacturing” funded by the Department of Heavy Industry & Automation Industry Association to set up a facility for Smart Manufacturing training and research. Setting up a lab and Smart Factory with state-of-the-art technologies.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Awards;
